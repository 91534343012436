<template>
  <div class="text-center">
    <v-dialog v-model="dialog" max-width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> {{ $t("delete") }} </v-card-title>

        <v-card-text class="subtitle-1">
          {{ $t('do-you-want-to-delete') }}: {{ $t(resource) }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="cancel">{{ $t("cancel") }}</v-btn>
          <v-btn color="blue darken-1" text @click="deleteResource">{{ $t("ok") }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import BridgeService from "@/services/BridgeService";

export default {
  name: "AreYouSureModal",
  props: {
    dialog: { required: true },
    resource: { required: true },
    id: {},
    prefix: {},
  },
  methods: {
    cancel() {
      this.$emit("toggle");
      this.$emit("deleted", false)
    },
    async deleteResource() {
      if (this.id) {
        await BridgeService.deleteResource(this.resource, this.id, this.prefix);
      }
      this.$emit("toggle");
      this.$emit("deleted", true);
    },
  },
};
</script>
