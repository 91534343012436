<template>
  <v-container class="py-10 px-6" fluid>
    <AreYouSureModal :resource="selectedResource" :id="selectedId" :dialog="deleteDialog" @toggle="toggleModal" />
    <v-card>
      <v-row dense>
        <v-toolbar color="green" dark>
          <v-toolbar-title class="d-flex justify-space-between full-width">
            <u class="mr-2">{{ $t(view) }}</u>
            <UploadButton v-if="$store.getters.get_access(moduleUsed, 2) && edit" title="" @file-update="startUpload"
              :data="{ type: fileType }" small color="secondary">
              <template slot="icon">
                <v-icon dark>mdi-plus</v-icon>
              </template>
            </UploadButton>
          </v-toolbar-title>
        </v-toolbar>
        <v-dialog v-model="dialog" max-width="600px">
          <v-card light>
            <v-form @submit.prevent="saveMedia">
              <v-card-title>
                <span class="headline"></span>
              </v-card-title>

              <v-card-text>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-layout column wrap>
                      <v-flex v-if="view === 'Files'">
                        <v-text-field label="Name" v-model="metadata.name"></v-text-field>
                      </v-flex>
                      <v-flex>
                        <v-textarea label="Comment" v-model="metadata.comment"></v-textarea>
                      </v-flex>
                      <v-flex v-if="view === 'Images'">
                        <v-date-picker v-model="metadata.date"></v-date-picker>
                      </v-flex>
                    </v-layout>
                  </v-layout>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" @click.native="clearUpload">Cancel</v-btn>
                <v-btn color="blue darken-1" type="submit" v-if="loading == false">Ok</v-btn>
                <v-btn color="blue darken-1" v-if="loading == true">
                  <v-progress-circular indeterminate color="white"></v-progress-circular>
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row v-if="view === 'Images'" justify="space-around">
        <v-col cols="6">
          <div v-for="img in  internalImages " :key="img.url">
            <inner-image-zoom :src="getImgUrl(img)" :zoomSrc="getImgUrl(img)">
              </inner-image-zoom>
            <div>{{ img.comment }}</div>
            <div>{{ img.date }}</div>
            <div class="img-controls">
              <v-btn v-if="$store.getters.get_access(moduleUsed, 2) && edit" dark small class="secondary ma-2"
                @click="toggleModal(img.id)">
                <v-icon small> mdi-delete </v-icon>
              </v-btn>
              <v-btn v-if="$store.getters.get_access(moduleUsed, 2) && edit" dark small class="secondary ma-2"
                @click="editMediaMetadata(img)">
                <v-icon small> mdi-pen </v-icon>
              </v-btn>
              <v-btn v-if="$store.getters.get_access(moduleUsed, 2)" dark small class="secondary ma-2">
                <a class="normal-white-text" :href="getImgUrl(img)" :download="`${img.url}.jpg`">
                  <v-icon small> mdi-download </v-icon>
                </a>
              </v-btn>
              <v-btn dark small class="secondary ma-2" @click="goTo(getImgUrl(img))">
                <v-icon small> mdi-magnify-plus </v-icon>
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="view === 'Files'" justify="space-around" class="ma-2 d-flex flex-column">
        <v-data-table :footer-props="{ 'items-per-page-text':''}" :no-data-text="$t('No data available')"	 :headers="headers" :items="internalImages" :items-per-page="15" class="elevation-1">
          <template v-slot:item.file="{ item }">
            <a :href="`https://bms.mpwt.link/media/${folder}/${item.url}`">{{
              item.url
            }}</a>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip top>
              <template v-slot:[`activator`]="{ on, attrs }" v-if="$store.getters.get_access(moduleUsed, 2)">
                <v-icon color="blue" medium class="mr-2" v-bind="attrs" v-on="on" @click="editMediaMetadata(item)">
                  mdi-pen
                </v-icon>
              </template>
              <span>Edit file</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:[`activator`]="{ on, attrs }" v-if="$store.getters.get_access(moduleUsed, 2)">
                <v-icon color="blue" medium class="mr-2" v-bind="attrs" v-on="on" @click="toggleModal(item.id)">
                  mdi-delete
                </v-icon>
              </template>
              <span>Delete file</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-row>
    </v-card>
  </v-container>
</template>



<script>
import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css';
import InnerImageZoom from 'vue-inner-image-zoom';

import UploadButton from "@/components/main/UploadButton.vue";
import AreYouSureModal from "@/components/main/AreYouSureModal.vue";
import imageZoom from 'vue-image-zoomer';

import BridgeService from "@/services/BridgeService";

export default {
  name: "ImagesGallery",
  components: {
    UploadButton,
    AreYouSureModal,
    InnerImageZoom
  },
  props: ["images", "fileType", "parentId", "edit"],
  data() {
    return {
      dialog: false,
      metadata: {
        name: "",
        comment: "",
        date: null,
      },
      loading: false,
      selectedResource: null,
      selectedId: null,
      deleteDialog: false,
      moduleUsed: "module_bridge_management",
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    view() {
      if (["bridge_drawing", "documentation_file", "training_file"].includes(this.fileType)) {
        return "Files";
      }
      return "Images";
    },
    sysCodeLanguage() {
      return this.$i18n.locale === "la" ? "description_lao" : "description_eng";
    },
    internalImages() {
      if (!this.images) {
        return [];
      }
      return [...this.images].sort((a, b) => a.id - b.id);
    },
    folder() {
      if (this.fileType === "bridge_photo") {
        return "inventory";
      }
      if (this.fileType === "element_damage_photo") {
        return "inspection";
      }
      if (this.fileType === "repair_history_photo") {
        return "repair";
      }
      if (this.fileType === "bridge_drawing") {
        return "files";
      }
      if (["documentation_file"].includes(this.fileType)) {
        return "documentation";
      }
      if (["training_file"].includes(this.fileType)) {
        return "training";
      }
      return "unknown";
    },
    headers() {
      return [
        {
          text: this.$t("name"),
          value: "name",
          sortby: true,
          color: "blue",
        },
        {
          text: this.$t("file"),
          value: "file",
          sortby: true,
          color: "blue",
        },
        {
          text: this.$t("comment"),
          value: "comment",
          sortby: true,
          color: "blue",
        },
        { text: "", value: "actions", color: "blue" },
      ];
    },
  },
  methods: {
    goTo(url) {
      window.open(url)
    },
    async toggleModal(id) {
      if (!this.deleteDialog) {
        this.selectedResource = this.fileType;
        this.selectedId = id;
        this.deleteDialog = true;
      } else {
        this.selectedResource = null;
        this.selectedId = null;
        this.deleteDialog = false;
        this.$emit("update");
      }
    },
    clearUpload() {
      this.dialog = false;
      this.formData = null;
      this.loading = false;
      this.metadata = {
        comment: "",
        name: "",
        date: null,
        file_type: this.fileType
      };
    },
    async saveMedia() {
      this.loading = true;
      if (this.formData) {
        this.formData.append("comment", this.metadata.comment);
        if (this.metadata.date) {
          this.formData.append("date", this.metadata.date);
        }
        this.formData.append("name", this.metadata.name);
        this.formData.append("file_type", this.metadata.file_type);
        await BridgeService.uploadMedia(
          this.fileType,
          this.parentId,
          this.formData
        );
      } else {
        await BridgeService.uploadMediaMetadata(
          this.fileType,
          this.parentId,
          this.metadata
        );
      }
      this.$emit("update");
      this.clearUpload();
      this.loading = false;
    },
    async startUpload(file) {
      this.formData = new FormData();
      this.formData.append("file", file);
      this.fileType = file.data.type;
      this.dialog = true;
      this.metadata = {
        comment: "",
        name: "",
        date: null,
        file_type: this.fileType
      };
    },
    getImgUrl(img) {
      return `https://bms.mpwt.gov.la/media/${this.folder}/${img.url}`;
    },
    editMediaMetadata(img) {
      this.dialog = true;
      this.metadata = { ...img, file_type: this.fileType };
    },
  },
};
</script>
<style scoped>
.normal-white-text {
  color: white !important;
  text-decoration: none !important;
}

table {
  background-color: #f0e6e6;
  font-family: arial, sans-serif;
  border-collapse: collapse;
  border: 1px solid rgb(227, 233, 233);
  width: 100%;
}

td,
th {
  width: 80px;
  border: 1px solid rgb(188, 194, 194);
  background-color: rgba(227, 233, 233);
  text-align: left;
  padding: 4px;
}

tr:nth-child(even) {
  background-color: #f7f5f5;
}
</style>